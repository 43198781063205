.text-default, .text-color-default, p{
  color: #14142B !important;
}
* {
  font-family: 'Poppins';
}
#header .header-btn-collapse-nav {
  background: unset;
}

.fa-bars::before{
  content: url("../../assets/img/controls/bars.svg");
}
